import nouns from 'locales/client_theme/nouns.js';

export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'D-BAT',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} D-BAT Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome!',
  'client_theme.ThemeSidebar.mission':
    'We provide a quality teaching environment with professional instruction to enhance the baseball and softball experience for players at all levels and help them reach their goals.',
  'client_theme.ThemeSidebar.learn_more': 'Learn More',
  'shared.OpenBookingScheduling.select_a_package': 'Select a Quantity',
  'containers.payments.fields.PaymentButton.continue': 'Complete',

  'shared.navigation.UserMenu.customer_selection': 'Change Location',

  'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.redeem_success':
    'Successfully redeemed {n} credits.',
  'containers.clientProfile.CashCreditsInfo.header': 'Credits',

  // Verbiage change from "credit(s)" to "session(s)" BEGIN
  'containers.events.admin.sessionSummaryDrawer.details.PurchaseModal.purchase_modal_button':
    'Add Sessions',
  'containers.events.admin.sessionSummaryDrawer.details.AddAttendee.no_credits':
    'No sessions',
  'containers.events.admin.sessionSummaryDrawer.details.PurchaseModal.purchase_modal_title':
    'No Sessions Available',

  'containers.openBookingDrawer.AccountProfileButtons.buy_credits':
    'Add Sessions',
  'containers.openBookingDrawer.AccountProfile.creditCtTooltip':
    'You currently have {creditsRemaining} sessions available to use for booking.',
  'containers.openBookingDrawer.AccountProfile.unlimitedCtTooltip':
    'You currently have unlimited sessions available to use for booking.',

  'containers.clientProfile.CreditInfo.header': 'Sessions',
  'containers.clientProfile.Schedule.CancelRegistrationModal.cancel_registration_confirmation':
    'Cancelling will remove you from this scheduled session and a session for the event will be deposited to your account for future use.',
  'containers.clientProfile.MembershipEmbed.Store.top_up_success':
    'Successfully added 10 sessions to platinum card.',
  'containers.clientProfile.MembershipEmbed.Store.top_up_error':
    'Error when added 10 sessions to platinum card.',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.redeem_title':
    'Redeem Sessions',

  'shared.checkout.OpenBookingCardContent.credit_need':
    '{scheduledSessionCount} {scheduledSessionCount, plural, one {Session} other {Sessions}} Needed',
  'shared.checkout.OpenBookingCardContent.credit_need_info':
    'You need {scheduledSessionCount} {scheduledSessionCount, plural, one {session} other {sessions}} to complete your registration. Select a session package below.',

  'containers.creditListDrawer.RegistrationPackageCard.no_packages':
    'No available sessions',
  'containers.creditListDrawer.CreditListDrawer.title': 'Sessions',
  'containers.creditListDrawer.RegistrationPackageCard.credits':
    '{n, plural, one {{n} Session} other {{n} Sessions}}',
  'containers.creditListDrawer.EmptyState.description':
    'Sessions will show up after purchase.',
  'containers.creditListDrawer.CreditGrantCard.credits':
    '{n, plural, one {{n} session} other {{n} sessions}}',
  'containers.creditListDrawer.CreditAdjustmentModal.title':
    'Session Adjustment',
  'containers.creditListDrawer.CreditAdjustmentModal.expiration_restricted':
    'Cannot change expiration of membership sessions.',
  'containers.creditListDrawer.Store.grant_update_success':
    'Sessions were successfully adjusted.',
  'containers.creditListDrawer.Store.grant_update_error':
    'Error while adjusting sessions. Please try again',

  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.refund_subtitle':
    'Compensation will be granted via session to participant(s).',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.revoke_subtitle':
    'Session(s) will NOT be returned to participant(s).',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.credits_waive_notice':
    'Sessions tied to a credit pass or membership cannot be waived.',

  'event_mgmt.display._EventSchedule.cancel_registration_confirmation':
    'Cancelling will remove you from this scheduled session and a session for the event will be deposited to your account for future use.',
  'event_mgmt.display._EventSchedule.cancel_registration_confirmation_admin':
    'Cancelling will remove the client from this scheduled session and a session for the event will be deposited to their account for future use.',
  'event_mgmt.editing._TabClassPricing.credit_pack_price':
    '{creditCount} {creditCount, plural, one {session} other {sessions}} • {price}',
  'event_mgmt.editing._TabClassPricing.description': `This event is booked using sessions the client can purchase using Credit Passes or ${nouns.Membership}s. `,
  'event_mgmt.editing._TabClassPricing.following_credits':
    'The following sessions are associated with this event:',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.refund_subtitle':
    'Compensation will be granted via session to participant(s).',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.revoke_subtitle':
    'Session(s) will NOT be returned to participant(s).',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.credits_waive_notice':
    'Sessions tied to a credit pass or membership cannot be waived.',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.refund_title':
    'Return Sessions',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.revoke_title':
    'Revoke Sessions',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.note_subtitle':
    'If compensation is granted in the form of sessions or the waive outstanding fee checkbox is not marked, any “pay later” balances will remain on the Balances Report until they are fully waived or fully paid. Actions cannot be undone.',

  'point_of_sale.EventScheduling.credit_refunded':
    '{clientName} will be refunded the session for future use.',
  'point_of_sale.EventScheduling.credit_expired':
    'Unfortunately session expired on {expDate} and {clientName} will not be refunded the session for future use.',
  'point_of_sale._NoClientCredits.credits_needed_text': `Sessions are needed to schedule this class. Purchase a Credit Pass or ${nouns.Membership}s to continue.`,
  'point_of_sale.products.list.cards._CreditPassCard.total_credits':
    ' • {total, select, Infinity {Unlimited} other {{total}} } Sessions',
  'point_of_sale._NoClientCredits.credits_needed_header': 'Sessions Needed!',

  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.refund_title':
    'Return Session',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.revoke_title':
    'Revoke Session',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.note_subtitle':
    'If compensation is granted in the form of sessions or the waive outstanding fee checkbox is not marked, any “pay later” balances will remain on the Balances Report until they are fully waived or fully paid. Actions cannot be undone.',

  'containers.classes.classesCancellation.ClassCancelModal.refund_subtitle':
    'Compensation will be granted via session to participant(s).',
  'containers.classes.classesCancellation.ClassCancelModal.revoke_subtitle':
    'Session(s) will NOT be returned to participant(s).',
  'containers.classes.classesCancellation.ClassCancelModal.refund_title':
    'Return Sessions',
  'containers.classes.classesCancellation.ClassCancelModal.revoke_title':
    'Revoke Sessions',
  'containers.classes.classesCancellation.ClassCancelModal.note_subtitle':
    'If compensation is granted in the form of sessions, any “pay later” balances will remain on the Balances Report until they are fully paid. Actions cannot be undone.',

  'containers.eventSessionCreation.EditConfirm.message':
    'Schedule Updated: Attendees will be removed and session returned to their account',

  'containers.eventScheduleCancellation.CancelScheduleModal.refund_subtitle':
    'Compensation will be granted via session to participant(s).',
  'containers.eventScheduleCancellation.CancelScheduleModal.revoke_subtitle':
    'Session(s) will NOT be returned to participant(s).',
  'containers.eventScheduleCancellation.CancelScheduleModal.refund_title':
    'Return Sessions',
  'containers.eventScheduleCancellation.CancelScheduleModal.revoke_title':
    'Revoke Sessions',
  'containers.eventScheduleCancellation.CancelScheduleModal.note_subtitle':
    'If compensation is granted in the form of sessions, any “pay later” balances will remain on the Balances Report until they are fully paid. Actions cannot be undone.',

  'shared.OpenBookingRegistrations.credit_expired':
    'Unfortunately your session expired on {expDate} and you will not be refunded the session for future use.',
  'shared.OpenBookingRegistrations.credit_refunded':
    'You will be refunded the session for future use.',

  'shared.OpenBookingScheduler.available_credits':
    'Available sessions: {creditsRemaining}',
  'shared.OpenBookingScheduler.credits_warning':
    '{sessionsCount} {sessionsCount, plural, one {session} other {sessions}} selected but only {creditsRemaining} {creditsRemaining, plural, one {session is} other {sessions are}} available. {creditsRemaining} {creditsRemaining, plural, one {session} other {sessions}} will be booked, and {sessionBooked} will be added to the cart.',
  'shared.OpenBookingScheduler.credits_warning_pkg_pricing':
    '{sessionsCount} {sessionsCount, plural, one {session} other {sessions}} selected. {creditsRemaining} {creditsRemaining, plural, one {session} other {sessions}} available. {sessionBooked} {sessionBooked, plural, one {session} other {sessions}} will be booked. Please remove or add more sessions to complete package requirements.',

  'credit_passes.CreditPassListing.credits_count':
    ' {count, plural, =0 {Sessions} one {Session} other {Sessions}}',
  'credit_passes._ClientCard.n_of_m_credits': '{n}/{m} sessions',
  'credit_passes._ClientCard.unlimited': 'Unlimited sessions',
  'credit_passes.editing._EditingForm.expiration_tooltip':
    'Sessions must be scheduled and used before the specified expiration date.',
  'credit_passes._CreditPassCredits.credits_for':
    '{quantity, plural, =0 {Unlimited} other {{quantity}}}  {quantity, plural, one {session} other {sessions}} for:',
  'credit_passes.editing._CreditSelector.create_credits': 'Create Sessions',
  'credit_passes.editing._CreditSelector.explanatory_text':
    'Offer free session for open booking events and classes.',
  'credit_passes.editing._CreditSelector.header_text': 'Sessions',
  'credit_passes.editing._CreditSelector.session_credits': 'Sessions',
  'credit_passes.editing._CreditSelector.additional_credits':
    'Additional Sessions',
  'credit_passes.editing._CreditSelector.hint_text_all_events':
    'Sessions can be applied for any available event.',
  'credit_passes.editing._CreditSelector.hint_text_event_types':
    'Sessions can be used for any available event within selected event types. If multiple event types are set sessions will be shared across them',
  'credit_passes.editing._CreditSelector.how_should_this_be_applied':
    'How should these sessions be applied?',
  'credit_passes.editing._CreditSelector.hint_text_event_specific':
    'Sessions can be used for selected events. If multiple events are set sessions will be shared across them.',

  'memberships.EventCreditSelector.additional_credits': 'Additional Sessions',
  'memberships.EventCreditSelector.expiration_tooltip':
    'Sessions must be scheduled and used before the expiration date.',
  'memberships.EventCreditSelector.create_discount': 'Create Sessions',
  'memberships.EventCreditSelector.explanatory_text': `Offer free sessions for open booking ${nouns.event}s.`,
  'memberships.EventCreditSelector.header_text': 'Sessions',
  'memberships.EventCreditSelector.session_credits': 'Sessions',
  'memberships.EventCreditSelector.allow_credit_rollover':
    'Allow sessions to roll over',
  'memberships.EventCreditSelector.grant_ahead_tooltip':
    'Granting ahead will provide clients with their sessions before their next billing cycle',
  'memberships.EventCreditSelector.how_should_this_be_applied':
    'How should these sessions be applied?',
  'memberships.EventCreditSelector.hint_text_all_events': `Sessions can be applied for any available ${nouns.event}.`,
  'memberships.EventCreditSelector.hint_text_event_types': `Sessions can be used for any available ${nouns.event} within selected ${nouns.event} types. If multiple ${nouns.event} types are set sessions will be shared across them`,
  'memberships.EventCreditSelector.hint_text_event_specific': `Sessions can be used for selected ${nouns.events}. If multiple ${nouns.events} are set sessions will be shared across them.`,

  'containers.reports.ordersReport.OrdersFilterDrawer.payment_method_credit':
    'Session',
  'containers.reports.ordersReport.OrdersReportHeader.ActiveFilters.credit':
    'Session',
  'containers.reports.ordersReport.OrdersReportTable.RowDesktop.payment_method_credit':
    'Session',

  'containers.reports.refundModal.RefundTo.credit': 'Session',

  'containers.reports.orderSummaryDrawer.OrderDetails.credit': 'Session',
  'containers.reports.orderSummaryDrawer.orderItemCards.CreditPassCard.credits_for':
    'Session For',
  'containers.reports.orderSummaryDrawer.orderItemCards.OpenBookingClassEventCard.credits':
    'Sessions',

  'containers.reports.balancesReport.Header.ActiveFilters.payment_method_credit':
    'Session',
  'containers.reports.balancesReport.Table.RowDesktop.payment_method_credit':
    'Session',
  'containers.reports.balancesReport.FilterDrawer.payment_method_credit':
    'Session',

  'containers.reports.balanceListDrawer.DetailItem.payment_method_credit':
    'Session',

  'containers.reports.payAllBalances.BalanceCard.payment_method_credit':
    'Session',

  'containers.reports.transactionsLedgerReport.Table.RowDesktop.payment_method_credit':
    'Session',

  'contacts.index.event_rates.EventRateForm.sensitive_rate':
    'Total monies owed will be calculated based on the total after discounts are applied. If a client uses sessions to book this event it is considered 100% discount and no payroll entry will be added.',
  'contacts.index.event_rates.EventRateCard.sensitive_rate':
    'Total monies owed will be calculated based on the total after discounts are applied. If a client uses sessions to book this event it is considered 100% discount and no payroll entry will be added.',

  'contacts.index.commission_rates.CommissionRateForm.sensitive_rate':
    'Total monies owed will be calculated based on the total after discounts are applied. If a client uses sessions to book this event it is considered 100% discount and no payroll entry will be added.',

  'containers.contacts.contactsStaff.RevokeAccessModal.text':
    '{staffName} is currently scheduled for {sessions}. Removing the staff member will do the following:\n' +
    '\n' +
    '• Staff member will be unassigned from all future sessions\n' +
    '• Deleted staff members can no longer be assigned to events or sessions\n' +
    '• Staff members cannot be reinstated\n' +
    '\n' +
    '{important} If the user is the only staff member associated with a future session, the session will be canceled and client payment/sessions for that session will be refunded.\n' +
    '\n' +
    'Click {confirm} to remove the staff member. {undone}.',

  'containers.sessionScheduling.SessionScheduling.btn_ok_info':
    '{selectedCount} / {allCount} Sessions',

  'containers.accountProfiles.AccountProfile.creditCtTooltip':
    'You currently have {creditsRemaining} sessions available to use for booking.',
  'containers.accountProfiles.AccountProfile.unlimitedCtTooltip':
    'You currently have unlimited sessions available to use for booking.',
  'containers.accountProfiles.AccountProfile.needAddtlCredits':
    'You need additional sessions to book this session.',

  'shared.Scheduler.unlimited_credits': 'Unlimited Sessions',
  // Verbiage change from "credit(s)" to "session(s)" END
};
